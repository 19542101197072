
    @import "config.scss";
 
.chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  color: white;
}
